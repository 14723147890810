import { EventInteraction, EventInteractionContext } from '@eventbrite/ads';
import { getFormattedTagsWithType } from '@eventbrite/categories';
import { Location } from '@eventbrite/discover-utils';
import { FormattedEvent } from '@eventbrite/event-renderer';
import {
    PRIMARY_BUCKET,
    SECONDARY_BUCKET,
} from '../../../../constants/constants';
import { getBaseTrackingContext } from '../../../../utils/tracking';

const HOMEPAGE_PLACEMENT_NAME = 'homepage';
const HOMEPAGE_SUB_INTERFACES: Record<string, string> = {
    [PRIMARY_BUCKET]: 'events_in',
    [SECONDARY_BUCKET]: 'more_events',
};

export function aggregateContext({
    bucketName = '',
    tabKey,
    bucketIndex,
    events = [],
    event,
    eventSearch,
    userId,
    locale,
    sessionId,
    guestId,
    location,
}: Omit<EventInteraction, 'action'> & {
    bucketName: string | undefined;
    tabKey: string | undefined;
    bucketIndex: number;
    events: FormattedEvent[];
    eventSearch: any;
    userId?: string;
    locale?: string;
    sessionId?: string;
    guestId?: string;
    location: Location;
}): EventInteractionContext {
    const baseTrackingContext = getBaseTrackingContext({
        event,
        events,
        userId,
        locale,
        guestId,
        sessionId,
    });

    return {
        ...baseTrackingContext,
        place: {
            name: HOMEPAGE_PLACEMENT_NAME,
            page: 1,
            context: {
                date_filter: Array.isArray(eventSearch.dates)
                    ? eventSearch.dates
                    : [eventSearch.dates],
                tags: getFormattedTagsWithType(event.tags),
                place_id: location?.placeId,
                is_online: location?.isOnline,
                latitude: null,
                longitude: null,
            },
            tabKey: tabKey,
            subInterface: {
                name: HOMEPAGE_SUB_INTERFACES[bucketName] || bucketName,
                position: `${bucketIndex + 1}`,
            },
            ...baseTrackingContext.place,
        },
    };
}
