import { EventInteraction, EventInteractionContext } from '@eventbrite/ads';
import {
    BUSINESS_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    getFormattedTagsWithType,
    MUSIC_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { logEvent } from '@eventbrite/statsig';
import {
    GA_HOME_CATEGORY,
    GA_HOME_FEED_CATEGORY,
    GA_VIEW_HOMEPAGE_FEED,
    GA_VIEW_LOGGEDOUT_HOMEPAGE_FEED,
} from '../../../constants/analytics';
import { getBaseTrackingContext } from '../../../utils/tracking';
import { HomeEngagementExperimentTrackingLocations } from '../constants/analytics';
import { WEEKEND_EVENTBRITE_CATEGORY } from '../constants/category';

// This is mainly here because on the feed we want to track extra stuff
// on componentDidMount in addition to the pageview we already are tracking,
// and we want to keep it consistent where the category will be different depending
// on logged-in or logged-out status.
export const getFeedTrackingProperties = (
    isAuthenticated?: boolean,
    userId?: string,
) => {
    // Non-auth'd users, Category is "home"
    let category = GA_HOME_CATEGORY;

    let action = GA_VIEW_LOGGEDOUT_HOMEPAGE_FEED;

    let dimensions = {};

    // Auth'd users, Category is "home-feed"
    if (isAuthenticated) {
        category = GA_HOME_FEED_CATEGORY;
        action = GA_VIEW_HOMEPAGE_FEED;
        dimensions = { userId };
    }

    return {
        category,
        action,
        nonInteraction: true,
        dimensions,
    };
};

export const logToStatsigHomeEngagement = (
    location: HomeEngagementExperimentTrackingLocations,
    isAuthenticated: boolean,
) => {
    if (HAS_WINDOW) {
        const isMobile = window.innerWidth < 600;
        const statsigLoggingString = getHomeEngagementEventString({
            locationKey: location,
            isMobile,
            isAuthenticated,
        });

        logEvent(statsigLoggingString);
    }
};

type DeviceFormat = 'Mobile' | 'Desktop';
type AuthenticatedStatus = 'Logged In' | 'Logged Out';
type HomeEngagementTrackingString =
    `Home - Click - ${HomeEngagementExperimentTrackingLocations} - ${DeviceFormat} - ${AuthenticatedStatus}`;

interface IGetHomeEngagementEventString {
    locationKey: HomeEngagementExperimentTrackingLocations;
    isAuthenticated: boolean;
    isMobile: boolean;
}

export const getHomeEngagementEventString = (
    params: IGetHomeEngagementEventString,
): HomeEngagementTrackingString => {
    const isMobile = params.isMobile ? 'Mobile' : 'Desktop';
    const isAuthenticated = params.isAuthenticated ? 'Logged In' : 'Logged Out';
    const locationString: HomeEngagementTrackingString = `Home - Click - ${params.locationKey} - ${isMobile} - ${isAuthenticated}`;

    return locationString;
};
const HOMEPAGE_PLACEMENT_NAME = 'homepage';

export const HOMEPAGE_SUB_INTERFACES: { [key: string]: string } = {
    [WEEKEND_EVENTBRITE_CATEGORY]: 'this_weekend',
    [MUSIC_EVENTBRITE_CATEGORY]: 'music',
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: 'food_and_drink',
    [BUSINESS_EVENTBRITE_CATEGORY]: 'business',
    [PERFORMING_EVENTBRITE_CATEGORY]: 'performing',
};

export function aggregateContext({
    bucketKey,
    bucketPosition,
    events = [],
    event,
    eventSearch,
    userId,
    locale,
    sessionId,
    guestId,
}: Omit<EventInteraction, 'action'> & {
    bucketKey?: string;
    bucketPosition?: string;
    events: FormattedEvent[];
    eventSearch: any;
    userId?: string;
    locale?: string;
    sessionId?: string;
    guestId?: string;
}): EventInteractionContext {
    const baseTrackingContext = getBaseTrackingContext({
        event,
        events,
        userId,
        locale,
        guestId,
        sessionId,
    });

    return {
        ...baseTrackingContext,
        place: {
            name: HOMEPAGE_PLACEMENT_NAME,
            page: 1,
            context: {
                date_filter: eventSearch.dates || null,
                query_terms: eventSearch.q || null,
                is_online: !!eventSearch.online_events_only,
                place_id: Array.isArray(eventSearch.places)
                    ? eventSearch.places[0]
                    : null,
                latitude: null,
                longitude: null,
                tags: getFormattedTagsWithType(event.tags),
            },
            subInterface: {
                name:
                    bucketKey && HOMEPAGE_SUB_INTERFACES[bucketKey]
                        ? HOMEPAGE_SUB_INTERFACES[bucketKey]
                        : bucketKey,
                position: bucketPosition,
            },
            ...baseTrackingContext.place,
        },
    };
}
